import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./index.scss"

const Section1 = ({ _logoSection }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(why-maximo-s1)/" } }) {
          nodes {
            frontmatter {
              buttonTitleSection1
              buttonTitleSection2
              descriptionSection1
              title1Section1
              title2Section1
            }
          }
        }
      }
    `)
    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _logoSection
  }
  return (
    <div
      id="section1"
      className="section1 col-12 col-md-8 text-start"
      data-testid="WhyMaximoS1"
    >
      <h1>
        <span className="title1-section1">{frontmatter.title1Section1}</span>
        <span className="title1-section2">{frontmatter.title2Section1}</span>
      </h1>
      <p
        dangerouslySetInnerHTML={{ __html: frontmatter.descriptionSection1 }}
      ></p>
      <button>
        <Link to="/how-it-works/" className="link">
          {frontmatter.buttonTitleSection1}
        </Link>
      </button>
      <button>
        <Link to="/get-started/" className="link">
          {frontmatter.buttonTitleSection2}
        </Link>
      </button>
    </div>
  )
}

export default Section1
