import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const Section4 = ({ _logoSection }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(why-maximo-s4)/" } }) {
          nodes {
            frontmatter {
              buttonTitle1Section4
              buttonTitle2Section4
            }
          }
        }
      }
    `)
    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _logoSection
  }
  return (
    <div className="section4 row " data-testid="WhyMaximoS4">
      <div className="col-12 text-center col-md-6 text-md-end">
        <Link to="/how-it-works/" className="button1-link">
          <button className="button1">
            {frontmatter.buttonTitle1Section4}
          </button>
        </Link>
      </div>
      <div className="col-12 text-center col-md-6 text-md-start">
        <Link to="/get-started/" className="button2-link">
          <button className="button2">
            {frontmatter.buttonTitle2Section4}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Section4
