import React from "react"
import LayoutLight from "../components/layout/light/layout"
import WhyMaxapps from "../components/why-maxapps"

const whyMaxapps = () => {
  return (
    <LayoutLight>
      <WhyMaxapps />
    </LayoutLight>
  )
}

export default whyMaxapps
