import React from "react"
import Section1 from "./section1"
import Section2 from "./section2"
import Section3 from "./section3"
import Section4 from "./section4"
import "./index.scss"
import Seo from "../seo"
import { Helmet } from "react-helmet"

const whyMaxapps = () => {
  return (
    <div id="whyMaxapps" className="whyMaxapps">
      <Seo
        title="Why choosing MAXapps IBM Maximo solution"
        description="MAXapps enables professionals using ibm Maximo mobile application to build performant, secure, flexible, and versatile business processes."
      />
      <Helmet prepend>
        <meta
          property="og:title"
          content="Why choosing MAXapps IBM Maximo solution | MAXapps"
        />
        <meta
          property="og:description"
          content="MAXapps enables IBM Maximo professionals to build performant, secure, flexible, and versatile business processes. Using the latest mobile technologies..."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/why-choosing-maxapps.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/why-choosing-ibm-maximo-solution/"
        />
        <link
          rel="canonical"
          key="https://www.maxapps-maximo.com/why-choosing-ibm-maximo-solution/"
          href="https://maxapps-maximo.com/why-choosing-ibm-maximo-solution/"
          data-react-helmet="true"
        ></link>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta
          name="twitter:title"
          content="Why choosing MAXapps IBM Maximo solution | MAXapps"
        />
        <meta
          name="twitter:description"
          content="MAXapps enables IBM Maximo professionals to build performant, secure, flexible, and versatile business processes. Using the latest mobile technologies..."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/why-choosing-maxapps.jpg"
        />
      </Helmet>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  )
}

export default whyMaxapps
