import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

function Section3({ _section3 }) {
  let section3 = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(why-maximo-s3)/" } }) {
          nodes {
            exports {
              section3 {
                description1
                description2
                srcIMG1
                srcIMG2
                title1
                title2
              }
            }
          }
        }
      }
    `)
    section3 = result.allMdx.nodes[0].exports.section3
  } catch (error) {
    section3 = _section3
  }
  return (
    <div className="section3" data-testid="WhyMaximoS3">
      {section3.map((item, index) => {
        return (
          <div key={index}>
            <div className="row paddingClass">
              <div className="col-12 col-md-7 pt-5">
                <div className="pb-5 pt-5">
                  <h2>
                    <span>{item.title1}</span>
                  </h2>
                </div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{ __html: item.description1 }}
                  ></p>
                </div>
              </div>
              <div className="col-12 col-md-5  d-flex justify-content-center align-items-center">
                <img
                  src={item.srcIMG1}
                  alt={`IBM Maximo: ${item.title1}`}
                  title={`IBM Maximo: ${item.title1}`}
                />
              </div>
            </div>
            <div className="row paddingClass">
              <div className="col-12 order-md-1 order-2 col-md-5  d-flex justify-content-center align-items-center">
                <img
                  src={item.srcIMG2}
                  alt={`IBM Maximo: ${item.title2}`}
                  title={`IBM Maximo: ${item.title2}`}
                />
              </div>
              <div className="col-12 order-1 order-md-2 col-md-7 pt-5">
                <div className="pb-5">
                  <h2>
                    <span>{item.title2}</span>
                  </h2>
                </div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{ __html: item.description2 }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Section3
