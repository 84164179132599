import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const Section2 = ({ _section2 }) => {
  let section2 = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(why-maximo-s2)/" } }) {
          nodes {
            exports {
              section2 {
                column
                srcIMG
                value
                value2
                value3
              }
            }
          }
        }
      }
    `)
    section2 = result.allMdx.nodes[0].exports.section2
  } catch (error) {
    section2 = _section2
  }
  return (
    <div className="section2 row" data-testid="WhyMaximoS2">
      {section2.map((item, index) => {
        return (
          <div className="col-12 col-md-6" key={index}>
            <div className="row">
              <div className="col-8 pt-5">
                <h2>{item.column}</h2>
              </div>
              <div className="col-4  d-flex justify-content-center align-items-center">
                <img
                  title={`IBM Maximo: ${item.column}`}
                  src={item.srcIMG}
                  alt={`IBM Maximo: ${item.column}`}
                />
              </div>
            </div>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: item.value }}
            ></p>
            {item?.value2 && (
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: item.value2 }}
              ></p>
            )}
            {item?.value3 && (
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: item.value3 }}
              ></p>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Section2
